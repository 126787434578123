import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { DashQueryType } from '@mirage/analytics/events/enums/dash_query_type';
import { PAP_Click_DashSubmitQuestion } from '@mirage/analytics/events/types/click_dash_submit_question';
import { PAP_Shown_DashSearchResult } from '@mirage/analytics/events/types/shown_dash_search_result';
import { ConversationMessage } from '@mirage/conversations/types';
import { normalizeQuestion } from '@mirage/mosaics/AnswersConversation/utils';
import { DynamicPanelPapProperties } from '@mirage/mosaics/DynamicPanel/atoms';
import * as dbxApiService from '@mirage/service-dbx-api';
import {
  DocSummaryQnaResult,
  DocSummaryQnaSuccess,
  isMultimodalContent,
  LoadingStatus,
  QnaAnswer,
  Role as QnaRole,
} from '@mirage/service-dbx-api/service/grpc/context_engine_apiv2/doc_summarization';
import { DocumentId } from '@mirage/service-dbx-api/service/grpc/context_engine_apiv2/gen/doc_summarization_pb';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import useSearchQueryId from '@mirage/shared/hooks/useSearchQueryId';
import { FeedbackOptions } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';

const logger = tagged('useSummaryQna');

export type DocSummaryQnaResults = { [key: string]: DocSummaryQnaResult };

type useSummaryQnaProps = {
  attachment?: SummarizableResult;
  papData?: DynamicPanelPapProperties;
  initialQuery?: string;
  actionSurfaceComponent?: ActionSurfaceComponent;
  enableSummary?: boolean;
  enableMultimodalSummary?: boolean;
  // test only
  initialResultSummaryQna?: DocSummaryQnaResults;
  initialCurrentSummaryQna?: DocSummaryQnaResult;
};

export const useSummaryQna = ({
  attachment,
  papData,
  initialQuery = '',
  actionSurfaceComponent,
  enableSummary,
  enableMultimodalSummary,
  // test only
  initialResultSummaryQna = {},
  initialCurrentSummaryQna,
}: useSummaryQnaProps) => {
  const [conversation, setConversation] = useState<ConversationMessage[]>([]);

  const {
    reportPapEvent,
    explicitAnswersSessionManager,
    searchSessionManager,
  } = useMirageAnalyticsContext();
  const { searchQueryUuid } = useSearchQueryId();
  const [requestId, setRequestId] = useState('');
  const [summaryExists, setSummaryExists] = useState(false);
  const [resultStatus, setResultStatus] = useState(LoadingStatus.idle);
  const [resultId, setResultId] = useState('');
  const [resultSummaryQna, setResultSummaryQna] =
    useState<DocSummaryQnaResults>(initialResultSummaryQna);

  const summaryQnaVariant = useFeatureFlagValue(
    'dash_2024_10_25_summary_ask_variant',
  );
  const isSummarizeMapReduceEnabled =
    useFeatureFlagValue('dash_2024_09_06_search_summary_enable_mapreduce') ===
    'ON';
  const mapReduceConfigs =
    (useFeatureFlagValue(
      'dash_2024_09_06_search_summary_mapreduce_configs',
    ) as { [key: string]: number }) || {};
  const isRAGEnabled =
    useFeatureFlagValue('dash_2024_09_06_search_doc_qna_enable_rag') === 'ON';
  const topKRAGValue = useFeatureFlagValue(
    'dash_2024_09_06_search_doc_qna_rag_topk',
  ) as number | -1;

  let currentSummaryQna: DocSummaryQnaResult | undefined =
    initialCurrentSummaryQna;

  const updateResultSummaryQna = (newResultSummaryQna: DocSummaryQnaResult) => {
    currentSummaryQna = newResultSummaryQna;
    setResultSummaryQna((prev) => {
      return {
        ...prev,
        [resultId]: newResultSummaryQna,
      };
    });
  };

  const setResultFeedback = (feedback: FeedbackOptions, index: number) => {
    const answer = resultSummaryQna?.[resultId]?.responses?.[index];
    if (answer) {
      setResultSummaryQna((prev) =>
        updateResultFeedback(prev, resultId, index, feedback),
      );
    }
  };

  const handlePapShownDashSearchResult = (queryType: DashQueryType) => {
    const lastAnswer =
      currentSummaryQna &&
      currentSummaryQna.responses?.[currentSummaryQna.responses.length - 1];
    if (lastAnswer) {
      papDashSubmitQuestion(queryType, lastAnswer);
    } else {
      logger.error(
        'Could not submit question to PAP, possible error %e',
        currentSummaryQna?.status,
      );
    }
  };

  useEffect(() => {
    if (currentSummaryQna && currentSummaryQna.status !== resultStatus) {
      setResultStatus(currentSummaryQna.status);
    }
  }, [resultSummaryQna[resultId]]);

  useEffect(() => {
    setResultId(attachment?.uuid || '');
    if (
      attachment &&
      resultSummaryQna[attachment.uuid]?.status &&
      resultSummaryQna[attachment.uuid]?.status !== LoadingStatus.error
    ) {
      setSummaryExists(true);
      currentSummaryQna = resultSummaryQna[attachment.uuid];
    } else {
      setSummaryExists(false);
    }
  }, [attachment?.uuid]);

  useEffect(() => {
    if (enableSummary && resultId)
      if (summaryExists) {
        setResultStatus(LoadingStatus.idle);
      } else {
        const connectorName = attachment?.connectorInfo?.connectorName || '';
        const fileTypeId = attachment?.fileTypeInfo?.id || '';
        const recordType = attachment?.recordType || '';
        const answerId = uuid();
        const isMultimodal =
          enableMultimodalSummary &&
          isMultimodalContent(connectorName, fileTypeId);
        const docId = {
          idType: {
            case: 'uuid',
            value: resultId,
          },
        } as DocumentId;

        let template = '';
        let modelName = '';

        if (isMultimodal) {
          template = 'summary_presentation';
          modelName = 'gpt-4o';
        } else if (summaryQnaVariant === 'V1') {
          template = 'summary_llama_8b';
          modelName = 'dropbox/llama31-8b-32k';
        } else if (summaryQnaVariant === 'V2') {
          template = 'summary_llama_8b_v2';
          modelName = 'dropbox/llama31-8b-32k';
        }

        const useMapReduce =
          (isMultimodal && isSummarizeMapReduceEnabled) || false;
        setResultStatus(LoadingStatus.pending);
        const subscription = dbxApiService.grpc
          .getDocSummary(
            docId,
            answerId,
            initialQuery,
            connectorName,
            recordType,
            template,
            modelName,
            useMapReduce,
            mapReduceConfigs,
          )
          .subscribe({
            next: updateResultSummaryQna,
            error: (error: Error) => {
              updateResultSummaryQna({
                status: LoadingStatus.error,
                description: i18n.t('doc_summary_unexpected_error'),
              });
              logger.error('Cannot fetch doc summary', error);
            },
            complete: () => {
              setResultStatus(LoadingStatus.idle);
              handlePapShownDashSearchResult('summary');
            },
          });

        return () => {
          subscription.unsubscribe();
        };
      }
  }, [enableSummary, enableMultimodalSummary, resultId, summaryExists]);

  const resultQuestion = ({
    queryString,
    role,
  }: {
    queryString: string;
    role: QnaAnswer['role'];
  }) => {
    const connectorName = attachment?.connectorInfo?.connectorName || '';
    const fileTypeId = attachment?.fileTypeInfo?.id || '';
    const answerId = uuid();
    const isMultimodal =
      enableMultimodalSummary &&
      attachment?.url &&
      isMultimodalContent(connectorName, fileTypeId);
    const docId = {
      idType: {
        case: 'uuid',
        value: resultId,
      },
    } as DocumentId;

    let template = '';
    let modelName = '';

    if (isMultimodal) {
      template = 'qna_presentation';
      modelName = 'gpt-4o';
    } else if (summaryQnaVariant == 'V1') {
      template = 'qna_llama_8b';
      modelName = 'dropbox/llama31-8b-32k';
    } else if (summaryQnaVariant == 'V2') {
      template = 'qna_llama_8b_v2';
      modelName = 'dropbox/llama31-8b-32k';
    }

    const topkRAG = isMultimodal && isRAGEnabled ? topKRAGValue : -1;
    const query = normalizeQuestion(queryString);
    const queryType =
      role === QnaRole.System ? 'pre_canned_question' : 'user_entry';
    setResultStatus(LoadingStatus.pending);
    if (resultSummaryQna[resultId]?.status === LoadingStatus.success) {
      const newResultSummaryQna: DocSummaryQnaSuccess = {
        ...resultSummaryQna[resultId],
        responses: [
          ...resultSummaryQna[resultId].responses,
          {
            status: LoadingStatus.pending,
            id: answerId,
            queryString: query,
            role: role,
          },
        ],
      };
      updateResultSummaryQna(newResultSummaryQna);
      const subscription = dbxApiService.grpc
        .getDocAnswer(
          docId,
          answerId,
          query,
          newResultSummaryQna,
          template,
          modelName,
          topkRAG,
        )
        .subscribe({
          next: updateResultSummaryQna,
          error: (error: Error) => {
            updateResultSummaryQna({
              status: LoadingStatus.error,
              description: i18n.t('doc_summary_unexpected_error'),
            });
            logger.error('Cannot fetch doc summary', error);
          },
          complete: () => {
            setResultStatus(LoadingStatus.idle);
            handlePapShownDashSearchResult(queryType);
          },
        });

      return () => {
        subscription.unsubscribe();
      };
    }
  };

  const resetConversation = () => {
    setConversation([]);
    setRequestId('');
    setResultSummaryQna({});
  };

  const papDashSubmitQuestion = (
    queryType: DashQueryType,
    response: QnaAnswer,
  ) => {
    const current = currentSummaryQna as DocSummaryQnaSuccess;
    reportPapEvent(
      PAP_Click_DashSubmitQuestion({
        queryString: response.queryString,
        numQuestionsAsked: current.responses?.length || 0,
        answerId: response.id,
        entryPoint: papData?.entryPoint,
        dashAnswersSessionId:
          explicitAnswersSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashQueryType: queryType,
        searchRequestId: papData?.searchRequestId,
        resultPosition: papData?.resultPosition,
        resultPositionNoCta: papData?.resultPositionNoCta,
        resultUuid: papData?.resultUuid,
        actionSurfaceComponent,
        featureLine: 'answers',
        dashAnswerRequestId: response.requestId,
        searchQueryUuid,
      }),
    );

    reportPapEvent(
      PAP_Shown_DashSearchResult({
        queryString: current.initialQuery,
        numQuestionsAsked: current.responses.length,
        connector: current.connectorName,
        answerString: response.text,
        answerId: response.id,
        entryPoint: papData?.entryPoint,
        dashAnswersSessionId:
          explicitAnswersSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashAnswerRequestId: response.requestId,
        actionSurfaceComponent,
        featureLine: 'answers',
      }),
    );
  };

  return {
    conversation,
    numQuestionsAsked: resultSummaryQna[resultId]?.responses?.length,
    dashAnswerRequestId: requestId,
    resetConversation,
    resultQuestion,
    resultSummaryQna,
    resultStatus,
    resultId,
    setResultFeedback,
    // exported for testing
    testOnly: {
      updateResultSummaryQna,
      currentSummaryQna,
    },
  };
};

// exported for testing

export const updateResponseFeedback = (
  responses: QnaAnswer[],
  index: number,
  feedback: FeedbackOptions,
) => {
  return responses.map((response, i) => {
    if (i === index) {
      return {
        ...response,
        status: LoadingStatus.idle,
        feedback,
      };
    }
    return response;
  });
};

export const updateResultFeedback = (
  prev: DocSummaryQnaResults,
  resultId: string,
  index: number,
  feedback: FeedbackOptions,
) => {
  if (prev?.[resultId]?.responses) {
    return {
      ...prev,
      [resultId]: {
        ...prev[resultId],
        responses: updateResponseFeedback(
          prev[resultId].responses,
          index,
          feedback,
        ),
      },
    };
  }
  return prev;
};
