import { SearchFilterResultSubTitle } from '@mirage/mosaics/SearchBarWithTypeahead/SearchFilterRowSubTitle';
import { SearchFilterIcon } from '@mirage/shared/icons/SearchFilterIcon';
import {
  type SearchFilter,
  SearchFilterType,
} from '@mirage/shared/search/search-filters';
import React from 'react';

export function getTypeaheadFilterTitle(filter: SearchFilter) {
  switch (filter.type) {
    case SearchFilterType.Connector:
      return filter.parameters.displayName;
    case SearchFilterType.ContentType:
      return filter.parameters.label;
    case SearchFilterType.Person:
      return filter.parameters.email;
    case SearchFilterType.LastUpdated:
      break;
    default:
      filter satisfies never;
  }
  return '';
}

export function getTypeaheadFilterDisplayProps(
  query: string,
  nextFilter: SearchFilter,
): {
  title: JSX.Element | string;
  subtitle?: JSX.Element | string;
  icon: JSX.Element;
} {
  switch (nextFilter.type) {
    case SearchFilterType.Connector:
    case SearchFilterType.ContentType:
    case SearchFilterType.Person:
      return {
        title: getTypeaheadFilterTitle(nextFilter),
        subtitle: (
          <SearchFilterResultSubTitle query={query} nextFilter={nextFilter} />
        ),
        icon: <SearchFilterIcon filter={nextFilter} />,
      };
    case SearchFilterType.LastUpdated:
      break;
    default:
      nextFilter satisfies never;
  }
  return {
    title: '',
    subtitle: <></>,
    icon: <></>,
  };
}
