import { dash } from '@dropbox/api-v2-client';
import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Modal } from '@dropbox/dig-components/modal';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  CheckmarkCircleLine,
  OpenLine,
} from '@dropbox/dig-icons/assets';
import ResultIcon from '@mirage/search/SearchResults/ResultIcon';
import { ResultRow } from '@mirage/search/SearchResults/ResultRow/ResultRow';
import { getTimeAgoStringFromTimestamp } from '@mirage/search/SearchResults/util/resultUtil';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import {
  createCuration,
  deleteCuration,
  getCuration,
  updateCuration,
} from '@mirage/service-curations';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { VERIFIED_CURATION_TAG } from '@mirage/shared/curations';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { showSnackbar } from '@mirage/shared/snackbar';
import { KeyCodes } from '@mirage/shared/util/constants';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { KeyboardEvent, useEffect, useState } from 'react';
import styles from './VerifyContentModal.module.css';

import type { SearchResult } from '@mirage/service-dbx-api';

// In sync with https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/server/-/blob/go/src/dropbox/dash/curations/client.go?L35
const QUERY_CHAR_LIMIT = 50;

type Props = {
  open: boolean;
  result: SearchResult;
  onClose: () => void;
  onOpenResult?: () => void;
  onSave?: (curation: dash.Curation | null) => void;
};

export function VerifyContentModal({
  open,
  result,
  onClose,
  onOpenResult,
  onSave,
}: Props) {
  const account = useDropboxAccount();
  const isDesktop = EnvCtx.surface === 'desktop';
  const isMobileSize = useIsMobileSize();
  const iconSize = isDesktop ? 'medium' : isMobileSize ? 'large' : 'xlarge';
  const { title, providerUpdateAtMs, highlights } = result;
  const timeAgoString = getTimeAgoStringFromTimestamp(providerUpdateAtMs, true);

  const [verifyCuration, setVerifyCuration] = useState<dash.Curation>();
  const [queryInput, setQueryInput] = useState<string>('');
  const [confirmingRemove, setConfirmingRemove] = useState(false);
  const [pinnedQueries, setPinnedQueries] = useState<Array<string>>([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // If curation freshness becomes an issue, ugrade this method to poll the backend
    const retrieveCuration = async () => {
      const response = await getCuration(result.uuid, VERIFIED_CURATION_TAG);
      if (response.success) {
        setVerifyCuration(response.curation);
        setPinnedQueries(response.curation?.exact_queries || []);
      }
    };
    retrieveCuration();
  }, []);

  const onDone = async () => {
    let response;
    setProcessing(true);
    if (verifyCuration) {
      response = await updateCuration(
        result.uuid,
        VERIFIED_CURATION_TAG,
        pinnedQueries,
      );
    } else {
      response = await createCuration(
        result.uuid,
        VERIFIED_CURATION_TAG,
        pinnedQueries,
      );
    }
    setProcessing(false);
    if (response.success) {
      if (onSave && response.curation) {
        onSave(response.curation);
      }
      showSnackbar({
        title: i18n.t('verify_content_save_success'),
        accessory: <UIIcon src={CheckmarkCircleLine} />,
      });
      onClose();
    } else {
      showSnackbar({ title: i18n.t('verify_content_save_failed') });
    }
  };

  const onRemove = async () => {
    setProcessing(true);
    const response = await deleteCuration(result.uuid);
    setProcessing(false);
    if (response.success) {
      onSave?.(null);
      showSnackbar({
        title: i18n.t('verify_content_remove_success'),
        accessory: <UIIcon src={CheckmarkCircleLine} />,
      });
      onClose();
    } else {
      showSnackbar({ title: i18n.t('verify_content_remove_failed') });
    }
  };

  const onAddQuery = () => {
    // TODO: Sanitize query againat malicious input
    const trimmedQuery = queryInput.trim();
    if (
      trimmedQuery &&
      !pinnedQueries.includes(trimmedQuery) &&
      trimmedQuery.length < QUERY_CHAR_LIMIT
    ) {
      setPinnedQueries((prev) => [...prev, trimmedQuery]);
    }
    setQueryInput('');
  };

  const onDeleteQuery = (query: string) => {
    setPinnedQueries((prev) =>
      prev.filter((existingQuery) => existingQuery !== query),
    );
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyCodes.enter && !!queryInput) {
      onAddQuery();
    }
  };

  const renderEditVerificationBody = () => {
    return (
      <>
        <Modal.Header className={styles.modalHeader}>
          <Modal.Title>{i18n.t('verify_content_modal_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalContent}>
          <Text color="subtle">{i18n.t('verify_content_modal_blurb')}</Text>
          <Box
            borderStyle="Solid"
            borderRadius="Small"
            marginTop="24"
            marginBottom="28"
          >
            <Box
              paddingX="20"
              paddingY="16"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: 'var(--dig-spacing__micro__large)' }}
              >
                <ResultIcon result={result} size={iconSize} />
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  style={{ alignSelf: 'stretch' }}
                >
                  <Text variant="label" size={isDesktop ? 'medium' : 'large'}>
                    {title}
                  </Text>
                  {timeAgoString && (
                    <Text
                      size={isDesktop ? 'small' : 'medium'}
                      color="subtle"
                      variant="label"
                    >
                      {timeAgoString}
                    </Text>
                  )}
                </Box>
              </Box>
              {onOpenResult && (
                <DigTooltip
                  title={i18n.t('result_open_button_text')}
                  placement="top"
                >
                  <IconButton
                    variant="borderless"
                    aria-label={i18n.t('open_result_aria')}
                    onClick={onOpenResult}
                  >
                    <UIIcon src={OpenLine} />
                  </IconButton>
                </DigTooltip>
              )}
            </Box>

            <Box borderTop="Solid">
              <Box
                display="flex"
                flexDirection="column"
                margin="8"
                backgroundColor="Background Subtle"
                padding="24"
                justifyContent="center"
                alignItems="center"
                borderRadius="XSmall"
              >
                <Box
                  width="100%"
                  backgroundColor="Background Base"
                  borderRadius="Medium"
                >
                  <ResultRow
                    result={{
                      ...result,
                      curations: [
                        {
                          curation_type: { '.tag': 'curation_type_verified' },
                          updated_by: {
                            display_name: account?.name.display_name,
                            profile_image_url: account?.profile_photo_url,
                            email: account?.email,
                          },
                          updated_at_ms: Date.now(),
                        },
                      ],
                    }}
                    query=""
                    iconSize={iconSize}
                    onLaunch={() => null}
                    snippets={highlights?.body?.text || []}
                    resultActionConfig={{}}
                    onCopyResult={() => null}
                    resultPosition={0}
                    noMargin={true}
                    displayOnly={true}
                    onAddToStack={() => null}
                    onSummarize={() => null}
                  />
                </Box>
                <Text color="subtle" className={styles.previewText}>
                  {i18n.t('verify_content_modal_preview_result')}
                </Text>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              marginBottom="8"
              style={{ gap: 'var(--dig-spacing__micro__xsmall)' }}
            >
              <Text isBold={true} variant="label" size="small">
                {i18n.t('verify_content_modal_add_query')}
              </Text>
              <Text color="subtle" variant="label" size="small">
                {i18n.t('verify_content_modal_add_query_subtitle')}
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" marginBottom="8">
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                style={{ gap: 'var(--dig-spacing__micro__small)' }}
              >
                <TextInput
                  wrapperProps={{ style: { boxShadow: 'none' } }}
                  onChange={(e) => setQueryInput(e.target.value)}
                  placeholder={i18n.t(
                    'verify_content_modal_add_query_placeholder',
                  )}
                  onKeyDown={handleKeyDown}
                  value={queryInput}
                />
                <IconButton
                  variant="opacity"
                  shape="standard"
                  disabled={
                    !queryInput || queryInput.trim().length >= QUERY_CHAR_LIMIT
                  }
                  onClick={onAddQuery}
                  aria-label={i18n.t('verify_content_modal_add_query_aria')}
                >
                  <UIIcon src={AddLine} />
                </IconButton>
              </Box>
              <Text
                size="xsmall"
                color={
                  queryInput.length < QUERY_CHAR_LIMIT ? 'subtle' : 'error'
                }
              >{`${queryInput.length}/${QUERY_CHAR_LIMIT} characters`}</Text>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ gap: 'var(--dig-spacing__micro__small)' }}
            >
              {pinnedQueries.map((query) => (
                <Chip key={query} onDelete={() => onDeleteQuery(query)}>
                  <Chip.Content>{query}</Chip.Content>
                </Chip>
              ))}
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer hasTopBorder={true}>
          {verifyCuration && (
            <Button
              variant="transparent"
              tone="destructive"
              onClick={() => setConfirmingRemove(true)}
            >
              {i18n.t('verify_content_modal_remove')}
            </Button>
          )}
          <Button variant="opacity" onClick={onClose}>
            {i18n.t('verify_content_modal_cancel')}
          </Button>
          <Button variant="primary" onClick={onDone} disabled={processing}>
            {i18n.t('verify_content_modal_done')}
          </Button>
        </Modal.Footer>
      </>
    );
  };

  const renderRemoveConfirmationBody = () => {
    return (
      <>
        <Modal.Header className={styles.modalHeader}>
          <Modal.Title>
            {i18n.t('verify_content_remove_modal_title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text isBold={true}>{title}</Text>&nbsp;
          {i18n.t('verify_content_modal_confirm_remove_body')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="opacity" onClick={() => setConfirmingRemove(false)}>
            {i18n.t('verify_content_modal_cancel')}
          </Button>
          <Button variant="primary" tone="destructive" onClick={onRemove}>
            {i18n.t('verify_content_modal_remove')}
          </Button>
        </Modal.Footer>
      </>
    );
  };

  return (
    <Modal
      open={open}
      isCentered={true}
      withCloseButton={i18n.t('close_modal_aria')}
      onRequestClose={onClose}
    >
      {confirmingRemove
        ? renderRemoveConfirmationBody()
        : renderEditVerificationBody()}
    </Modal>
  );
}
