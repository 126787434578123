import { stacks } from '@dropbox/api-v2-client';
import { Metadata } from '@dropbox/dash-component-library';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { GlobeLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { PAP_Shown_TabPanel } from '@mirage/analytics/events/types/shown_tab_panel';
import { PAP_View_DashStack } from '@mirage/analytics/events/types/view_dash_stack';
import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { ModuleCard } from '@mirage/dash-component-library/components/DashCard/ModuleCard';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import {
  TileCard,
  TileCardGrid,
} from '@mirage/dash-component-library/components/TileCard';
import { NoContentPlaceholder } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/NoContentPlaceholder';
import { StacksTabFilterButton } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/StacksTabFilterButton';
import { useStacksTabFilterPreference } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/useStacksTabFilterPreference';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useLastViewedStackInfo } from '@mirage/service-recent-content/hooks/useLastViewedStackInfo';
import {
  StackFilterOptionToPapName,
  StackSortDirection,
  StackSortOption,
} from '@mirage/service-settings/service/types';
import { useIsPublicSharingAllowed } from '@mirage/service-stack-admin-settings/hooks';
import {
  stackDerivePAPProps,
  stackGetShareId,
} from '@mirage/service-stacks/service/utils';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { Blur } from '@mirage/shared/blur/Blur';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import { getTimeAgoString } from '@mirage/shared/util/time';
import { convertStackToWorkingSetCardData } from '@mirage/stacks/AllStacksGrid/AllStacks';
import { useDynamicExpandableContent } from '@mirage/stacks/FullScreenStack/useDynamicExpandableContent';
import {
  useSortedStacks,
  useSortedStacksWithItemsBySortOption,
} from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import { WorkingSetCardData } from '@mirage/working-set';
import { usePrevious } from '@react-hookz/web';
import classnames from 'classnames';
import { useAtomValue } from 'jotai/index';
import * as React from 'react';
import { forwardRef, Ref, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import styles from './StacksTabPanel.module.css';

const MAX_VISIBLE_COUNT = 8;

export const StacksTabPanel = ({
  headerButtonSlot,
  isActive,
  maxCardsPerRow,
}: {
  headerButtonSlot: Element | null;
  isActive: boolean;
  maxCardsPerRow: number;
}) => {
  const currentAccount = useDropboxAccount();
  const augustRevision = useAllStacksAugustRevisionEnabled();
  const privacyModeEnabled = useAtomValue(privacyModeEnabledAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isPublicSharingAllowed = useIsPublicSharingAllowed();
  const navigate = useNavigate();
  const indexOfSeeAllCard = maxCardsPerRow * 2 - 1;

  const sortedStacks = useSortedStacks();
  const { startPageStackFilterPreference } = useStacksTabFilterPreference();
  const papDashStackFilterName =
    StackFilterOptionToPapName[startPageStackFilterPreference];
  const sortPreferenceByRecentlyViewed = {
    // Note this is special sort option that buckets all company pinned stacks first then by view order
    option: StackSortOption.COMPANY_AND_VIEWED,
    direction: StackSortDirection.DESC,
  };
  const { stacks: filteredSortedStacks } = useSortedStacksWithItemsBySortOption(
    sortPreferenceByRecentlyViewed,
    startPageStackFilterPreference,
  );

  const onSelectStack = React.useCallback(
    (stack: stacks.Stack) => {
      reportPapEvent(
        PAP_Open_DashLink({
          actionSurfaceComponent: 'tab_panel',
          featureLine: 'stacks',
          dashStackFilterName: papDashStackFilterName,
          ...stackDerivePAPProps(stack),
        }),
      );

      reportPapEvent(
        PAP_View_DashStack({
          actionSurfaceComponent: 'tab_panel',
          featureLine: 'stacks',
          dashStackFilterName: papDashStackFilterName,
          ...stackDerivePAPProps(stack),
        }),
      );

      navigate(`/stacks/${stackGetShareId(stack)}`);
    },
    [navigate, reportPapEvent, papDashStackFilterName],
  );

  const convertStacksToWorkingSetCardData = useCallback(
    (stacks: stacks.Stack[]) => {
      return stacks.map((stack: stacks.Stack) => {
        return convertStackToWorkingSetCardData(
          stack,
          stacks,
          onSelectStack,
          {}, // don't need stackItemsMap yet, since we're not displaying nested items
          undefined, // not hooking in menu open logic
          currentAccount,
          augustRevision,
          isPublicSharingAllowed,
          undefined, // not hooking in menu open logic
        );
      });
    },
    [augustRevision, currentAccount, isPublicSharingAllowed, onSelectStack],
  );

  const stackList = React.useMemo(() => {
    return convertStacksToWorkingSetCardData(filteredSortedStacks ?? []);
  }, [convertStacksToWorkingSetCardData, filteredSortedStacks]);
  const isLoading = sortedStacks === undefined;
  const { expanded, showMore, setExpanded, wrapperRef, numVisible } =
    useDynamicExpandableContent(`div.${styles.selectorStacksCard}`, isActive);

  const previousIsActive = usePrevious(isActive);

  useEffect(() => {
    // Let's only log when we go from not active -> active
    if (isActive && !previousIsActive) {
      reportPapEvent(
        PAP_Shown_TabPanel({
          startPageAction: 'click_stacks_tab',
          isEmpty: stackList.length === 0,
          featureLine: 'stacks',
          actionSurfaceComponent: 'tab_panel',
          dashStackFilterName:
            StackFilterOptionToPapName[startPageStackFilterPreference],
        }),
      );
    }
  }, [
    isActive,
    reportPapEvent,
    stackList,
    isLoading,
    startPageStackFilterPreference,
  ]);

  const handleToggleButton = useCallback(() => {
    if (expanded) {
      reportPapEvent(
        PAP_Collapse_DashCard({
          featureLine: 'stacks',
          actionSurfaceComponent: 'tab_panel',
        }),
      );
    } else {
      reportPapEvent(
        PAP_Expand_DashCard({
          featureLine: 'stacks',
          actionSurfaceComponent: 'tab_panel',
        }),
      );
    }

    setExpanded((prev) => !prev);
  }, [expanded, setExpanded, reportPapEvent]);

  const withAccessory: React.ReactNode[] = [
    <StacksTabFilterButton key="filter-button" />,
  ];
  if (showMore) {
    withAccessory.push(
      <Box
        marginLeft="Micro Medium"
        className={styles.buttonContainer}
        key={'box-container-btns'}
      >
        <Button
          variant="transparent"
          onClick={handleToggleButton}
          size="small"
          aria-hidden={!expanded}
        >
          {i18n.t('show_less_answer')}
        </Button>
        <Button
          variant="transparent"
          onClick={handleToggleButton}
          size="small"
          aria-hidden={expanded}
        >
          {i18n.t('show_more_answer')}
        </Button>
      </Box>,
    );
  }

  return (
    <Box
      style={{
        display: isActive ? 'block' : 'none',
        position: 'relative',
        overflowY: 'hidden', // fixes double-scroll bug on safari
      }}
    >
      {headerButtonSlot && createPortal(withAccessory, headerButtonSlot)}
      <Blur isBlurred={privacyModeEnabled} blurAmount="high">
        <StacksTileCardGrid
          expanded={expanded}
          isLoading={isLoading}
          numVisible={numVisible}
          stackCardList={stackList}
          ref={wrapperRef}
          indexOfSeeAllCard={indexOfSeeAllCard}
        />
      </Blur>
    </Box>
  );
};

const StacksTileCardGrid = forwardRef(
  (
    {
      expanded,
      stackCardList,
      isLoading,
      numVisible,
      indexOfSeeAllCard,
    }: {
      expanded: boolean;
      stackCardList: WorkingSetCardData[];
      isLoading: boolean;
      numVisible: number;
      indexOfSeeAllCard: number;
    },
    ref?: Ref<HTMLDivElement>,
  ) => {
    const lastViewedInfo = useLastViewedStackInfo();

    const showNoStacks = !isLoading && stackCardList.length === 0;
    if (showNoStacks) {
      return <NoContentPlaceholder message={i18n.t('no_stacks_from_filter')} />;
    }

    return (
      <TileCardGrid
        expanded={expanded}
        tileCardClassName={styles.selectorStacksCard}
        isLoading={isLoading}
        loadingTileCount={MAX_VISIBLE_COUNT}
        ref={ref}
        numVisible={numVisible}
      >
        {stackCardList.map((stackData, i) => {
          return i !== indexOfSeeAllCard ? (
            <StackCard
              key={stackData.id}
              stackData={stackData}
              hidden={i >= numVisible}
              selectorClassname={styles.selectorStacksCard}
              lastViewedInfo={lastViewedInfo[stackData.namespaceId]}
            />
          ) : (
            <SeeAllStackCard
              key="see-all-card"
              selectorClassname={styles.selectorStacksCard}
              numTotalStacks={stackCardList.length}
              hidden={i >= numVisible}
            />
          );
        })}
      </TileCardGrid>
    );
  },
);
StacksTileCardGrid.displayName = 'StacksTileCardGrid';

type StackCardProps = {
  stackData: WorkingSetCardData;
  selectorClassname: string;
  hidden: boolean;
  lastViewedInfo?: number;
};
export const StackCard = ({
  stackData,
  selectorClassname,
  hidden,
  lastViewedInfo,
}: StackCardProps) => {
  return (
    <TileCard
      className={selectorClassname}
      style={{ height: 154 }}
      hidden={hidden}
      onClick={stackData.onClickCard}
      visibleUrl={`/stacks/${stackData.id}`}
      titleLinesBeforeTruncate={2}
      icon={
        <DashStackIcon
          size="medium"
          colorIndex={stackData.colorIndex}
          emoji={stackData.emoji}
        />
      }
      title={stackData.title}
      withInteractiveAccessory
      metadata={
        <Metadata size="small" withDividers="blank">
          {getMetadataForStackCard(stackData, lastViewedInfo)}
        </Metadata>
      }
    />
  );
};

const SeeAllStackCard = ({
  selectorClassname,
  numTotalStacks,
  hidden,
}: {
  selectorClassname: string;
  numTotalStacks: number;
  hidden: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <ModuleCard
      className={classnames(styles.card, selectorClassname, {
        [styles.hidden]: hidden,
      })}
      breakout={'xsmall'}
      onClick={() => navigate('/stacks')}
    >
      <>
        <Text isBold variant="label" size="medium">
          {i18n.t('see_all')}
        </Text>
        <Text variant="paragraph" size="medium">
          {i18n.t('count_stacks', { count: numTotalStacks })}
        </Text>
      </>
    </ModuleCard>
  );
};

const getMetadataForStackCard = (
  stackCard: WorkingSetCardData,
  lastViewedInfo?: number,
) => {
  const { accessLevelIcon, accessLevel } = stackCard;
  let metaLabel = '';
  if (lastViewedInfo) {
    metaLabel = i18n.t('viewed_ago', {
      timeAgoString: getTimeAgoString(lastViewedInfo),
    });
  } else {
    metaLabel = stackCard.accessLevel;
  }

  // Not ideal: workaround to set "public" stacks to use global icon; would rather this get
  // set in accessLevelIconSrcForMemberCount, but don't want to break current implementation on stacks page
  let uiIconSrc = accessLevelIcon;
  if (stackCard.accessLevel === i18n.t('public_accesslevel')) {
    uiIconSrc = GlobeLine;
  }

  // Not ideal to rely on i18n text and should hanlde on the convert stack to card data fn
  if (accessLevel === i18n.t('private_accesslevel_multiple_users')) {
    return (
      <Metadata.Item>{stackCard.getMembersComponent('xsmall')}</Metadata.Item>
    );
  }

  return (
    <>
      <Metadata.Item>
        <UIIcon src={uiIconSrc} isOpticallyAligned />
      </Metadata.Item>
      {metaLabel && (
        <Metadata.Item>
          <DashTruncateWithTooltip
            lines={1}
            tooltipProps={{
              title: metaLabel,
            }}
          >
            {metaLabel}
          </DashTruncateWithTooltip>
        </Metadata.Item>
      )}
    </>
  );
};
