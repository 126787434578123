import { getCachedConnectorConnections } from '@mirage/service-connectors';
import {
  getPeopleSuggestions,
  performPeopleSearch,
} from '@mirage/service-people';
import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import { filterableContentTypes } from '@mirage/shared/content-type/content-types';
import {
  contentTypeToSearchFilter,
  extractTrailingSearchFilter,
  personToSearchFilter,
  SearchFilter,
  SearchFilterKeyword,
  SearchFilterType,
  toFilterBinding,
} from '@mirage/shared/search/search-filters';
import Sentry from '@mirage/shared/sentry';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import debounce from 'lodash.debounce';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';

import type { ConnectorConnection } from '@mirage/service-dbx-api';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { ConnectorFilter } from '@mirage/shared/search/search-filters';
import type { Observable } from 'rxjs';

export const search = wrappers.wrapped(SourceId.SearchFilter, raw);

export function raw(
  query: string,
  _config: typeahead.Config,
  _cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx
    .from(_internalSearch(query))
    .pipe(op.mergeMap((result) => result))
    .pipe(op.map((result) => primitives.searchFilter(result.id, result)));
}

// exported for testing only
// Consider moving this method else if the need arises
export function connectionToSearchFilter(connection: ConnectorConnection) {
  if (
    !connection?.connector?.id_attrs?.type ||
    !connection?.connector?.branding?.display_name
  ) {
    return;
  }

  return {
    id: connection?.connector?.id_attrs?.type,
    type: SearchFilterType.Connector,
    parameters: {
      connectorId: connection.connector.id_attrs?.type,
      displayName: connection.connector.branding?.display_name,
      iconLightSrc: connection.connector.branding?.icon_src,
      iconDarkSrc: connection.connector.branding?.icon_dark_src,
    },
  } as ConnectorFilter;
}

export function filterUsing(
  keyword: SearchFilterKeyword,
  filterText: string,
  query: string,
): (searchFilter: SearchFilter) => boolean {
  return (searchFilter) => {
    return (
      isDefined(searchFilter) &&
      searchFilter.id.toLowerCase().includes(filterText.toLowerCase()) &&
      !query
        .toLowerCase()
        .includes(toFilterBinding(keyword, searchFilter.id.toLowerCase()))
    );
  };
}

export function sortBy<
  T extends { parameters: Record<K, string> },
  K extends keyof T['parameters'],
>(parameter: K) {
  return (a: T, b: T): number => {
    return a.parameters[parameter].localeCompare(b.parameters[parameter]);
  };
}

export function mapWith<T extends object, R extends SearchFilter>(
  mapTransformer: (typeaheadResult: T) => R | undefined,
) {
  return (typeaheadResult: T): R => mapTransformer(typeaheadResult)!;
}

export function debounceAsync<
  T extends (...args: string[]) => Promise<SearchFilter[]>,
>(func: T, wait: number): (...args: Parameters<T>) => ReturnType<T> {
  let pendingPromises: Array<{
    resolve: (f: SearchFilter[] | PromiseLike<SearchFilter[]>) => void;
    reject: (e: unknown) => void;
  }> = [];

  const debouncedFunc = debounce(async (...args: Parameters<T>) => {
    try {
      const result = await func(...args);
      pendingPromises.forEach(({ resolve }) => resolve(result));
    } catch (error) {
      pendingPromises.forEach(({ reject }) => reject(error));
    } finally {
      pendingPromises = [];
    }
  }, wait);

  return (...args: Parameters<T>) => {
    return new Promise((resolve, reject) => {
      pendingPromises.push({ resolve, reject });
      debouncedFunc(...args);
    }) as ReturnType<T>;
  };
}

const SEARCH_DEBOUNCE_MS = 100;

const peopleInternalSearchDebounced = debounceAsync(
  async (filterText: string, query: string): Promise<SearchFilter[]> => {
    const peopleFn = filterText.length
      ? performPeopleSearch
      : getPeopleSuggestions;

    try {
      const people = await peopleFn(filterText);

      return people
        .map(mapWith(personToSearchFilter))
        .filter(filterUsing('by', filterText, query))
        .sort(sortBy('email'));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed debounced people search in Typeahead Results: ', e);
      return [];
    }
  },
  SEARCH_DEBOUNCE_MS,
);

export async function _internalSearch(query: string): Promise<SearchFilter[]> {
  const { filterType, query: filterText } = extractTrailingSearchFilter(query);

  if (!filterType) return [];

  try {
    if (filterType === SearchFilterType.Connector) {
      const connectors = await getCachedConnectorConnections();
      return connectors
        .map(mapWith(connectionToSearchFilter))
        .filter(filterUsing('in', filterText, query))
        .sort(sortBy('displayName'));
    } else if (filterType === SearchFilterType.ContentType) {
      return filterableContentTypes
        .map(mapWith(contentTypeToSearchFilter))
        .filter(filterUsing('type', filterText, query))
        .sort(sortBy('label'));
    } else if (filterType === SearchFilterType.Person) {
      return await peopleInternalSearchDebounced(filterText, query);
    } else {
      return [];
    }
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
}
